@font-face {
  font-family: "Helvetica";
  src: url("../fonts/helvetica/Helvetica-Bold.ttf") format("truetype"),
    url("../fonts//helvetica/Helvetica.ttf"),
    url("../fonts//helvetica/Helvetica-Light.ttf");
}

h1 {
  font-family: "Helvetica";
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #373737;
}

.cssColCenter {
  float: none;
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: #7c1813;
  border-color: #7c1813;
}

.list-group-item.active {
  z-index: 2;
  color: #000;
  background-color: #e0e0e0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: 0.3s;
}

.container_app {
  width: 100%;
  height: 100vh;
  padding: 30px 10% 10% 10%;
  overflow: auto;
}

.card-body {
  padding-bottom: 15px !important;
  padding-top: 30px !important;
  padding-left: 10px;
  padding-right: 10px;
}

.cssCard {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  margin-bottom: 15px;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
}

.cssCardImage {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.cssCardTitle {
  font-family: "Helvetica";
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #373737;
}

.cssCardText {
  font-family: "Helvetica";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #585656;
  margin-top: 14px;
  margin-bottom: 25px;
}

.cssButton {
  font-family: "Helvetica";
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

/**
 * Circle Styles
 */

.circle {
  width: 50px;
  height: 50px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background: #ffffff;
  font-family: BlockBE;
  font-size: 20px;
  color: #fff;
  padding-top: 10px;
}

/* .circle {
    width: 48.6px;
    position: relative;
    display: inline-grid;
    background-color: transparent;
    color: #fff;
    font-family: BlockBE;
    font-size: 20px;
    text-align: center;
}

.circle:after {
    display: block;
    padding-bottom: 100%;
    height: 0;
    border-radius: 50%;
    background-color: #7C1813;
    content: "";
}

.circle__inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.circle__wrapper {
    display: table;
    width: 100%;
    height: 100%;
}

.circle__content {
    display: table-cell;
    vertical-align: middle;
} */

.cssNomeGarcom {
  font-family: "Helvetica";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #292f36;
  margin-left: 5px;
  margin-right: 5px;
  letter-spacing: 2px;
}

.titulo_modal {
  font-family: "Helvetica";
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #373737;
  margin-top: 28px;
  margin-bottom: 28px;
}

.cssNomeGarcomModal {
  font-family: "Helvetica";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #292f36;
}

.cssTextCelular {
  font-family: "Helvetica";
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #585656;
  margin-top: 42px;
}

.cssTextBonusGerado {
  font-family: "Helvetica";
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #585656;
  margin-top: 42px;
}

.cssInputCelular {
  width: 385.7px;
  height: 72.8px;
  border-radius: 5px;
  box-shadow: 0 7px 17px 0 rgba(0, 0, 0, 0.05);
  border: solid 1.5px rgba(231, 231, 231, 0.75);
  background-color: #ffffff;
  display: inline-block;
  font-family: "DINPro";
  font-size: 22px;
  font-weight: 500;
  color: #363839;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.cssButtonCelular {
  width: 232px;
  height: 46px;
  /* opacity: 0.5; */
  font-family: "Helvetica";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  background-color: #7c1813;
  border-color: #7c1813;
}

.cssTextBonusIndisponivel {
  font-family: "Helvetica";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #585656;
  margin-top: 27px;
  margin-bottom: 41px;
}

.cssButtonConfirmacaoInfo {
  width: 245px;
  height: 53px;
  font-family: BlockBE;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.cssButtonObrigado {
  width: 232px;
  height: 46px;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding-top: 8px;
}

.boxPesquisaSatisfacao {
  width: 130px;
  height: 139px;
  border-radius: 5px;
  box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  padding-top: 17px;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 10px;
  float: left;
  cursor: pointer;
}

.active {
  border-radius: 5px;
  border: 3px solid rgba(0, 0, 0, 1);
}

.boxPesquisaSatisfacao img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.boxPesquisaSatisfacao p {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #585656;
}

.boxOptionDemografico {
  width: 150px;
  height: 77px;
  border-radius: 5px;
  box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  text-align: center;
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 30px;
  padding-bottom: 17px;
  margin-right: 10px;
  cursor: pointer;
  font-family: DINPro;
  font-size: 20px;
}

/*TABLET*/

@media (min-width: 481px) and (max-width: 767px) {
  .cssCardTitle {
    font-size: 18px;
  }
  .cssCardText {
    font-size: 18px;
  }
  .cssButton {
    font-size: 20px;
  }
  .circle {
    width: 40px;
    height: 40px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background: #ffffff;
    font-family: BlockBE;
    font-size: 15px;
    color: #fff;
    padding-top: 9px;
  }
}

/*CELULAR*/

@media (min-width: 320px) and (max-width: 480px) {
  .container_app {
    padding: 15px;
    padding: 30px 6% 6% 6%;
  }
  .cssInputCelular {
    width: 275px;
  }
  h1 {
    font-size: 22px;
  }
}

.input-cellphone-container {
  width: "100%";
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.active {
  border: 0px solid black;
}
